import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Spinner } from "react-bootstrap";
import { NButton } from "components";
import { updateTask } from "services/crmService";
import { getTaskList } from "store/actions/async/crm-async.action";
import moment from "moment";

import CallResults from "./CallResults";
import CallResponses from "./CallResponses";

import styles from "./NCallingInfo.module.css";

import { AnonymousContainer } from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NBTextArea } from "components/Nimbiv2";
import { getGlobalConfig, getText } from "utils";
import { useTranslationLocal } from "hooks";
import { cleanTaskGroup } from "store/actions/crm.actions";

const NCallingInfo = ({
  children,
  task,
  resultCategory,
  onChange,
  hideSaveButton,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { callResults, studentResponseList: callResponses } = useSelector(
    (state) => state.crm
  );
  const { user } = useSelector((state) => state.auth);
  const { moments, tabTask: tab } = useSelector(
    (state) => state.crm,
    _.isEqual
  );

  const [studentResponse, setStudentResponse] = useState(null);
  const [comments, setComments] = useState("");
  const [customSubject, setCustomSubject] = useState("");
  const [isPending, setPending] = useState(false);
  const [callResult, setCallResult] = useState(null);
  const [isResponsesDisabled, setResponsesDisabled] = useState(true);
  const [isOtherSubjectDisabled, setOtherSubjectDisabled] = useState(true);
  const [callResultValue, setCallResultValue] = useState(null);
  const [callResultValueType, setCallResultValueType] = useState(null);
  const [callResponseValue, setCallResponseValue] = useState(null);
  const [callResponseValueType, setCallResponseValueType] = useState(null);
  const [callResultRequiered, setCallResultRequiered] = useState(false);

  const [isInvalidOther, setIsInvalidOther] = useState(false);
  const [requiredValue, setRequiredValue] = useState(false);

  const [derive, setDerive] = useState("");
  const [observationRequired, setObservationRequired] = useState(false);
  const [studentResponseRequired, setStudentResRequired] = useState(false);

  const student = useMemo(() => {
    const { student_tracking: studentInfo = null } = task;
    return studentInfo;
  }, [task]);

  const { initiativesCall, selectInitiativeCall } = useSelector(
    (state) => state.initiative
  );

  const activeInitiative = useMemo(() => {
    const results = initiativesCall.filter(
      (initiative) =>
        initiative.selected || initiative.id === selectInitiativeCall
    );
    return results.length > 0 ? results[0] : null;
  }, [initiativesCall, selectInitiativeCall]);

  useEffect(() => {
    if (resultCategory === "exception") setResponsesDisabled(false);
  }, [resultCategory]);

  const saveTask = useCallback(async () => {
    if (!callResult) {
      setCallResultRequiered(true);
      return false;
    }
    if (callResult && !isResponsesDisabled && !studentResponse) {
      setStudentResRequired(true);
      return false;
    }
    if (
      callResult.input_type != null &&
      (callResultValue === null || callResultValue === "")
    ) {
      setCallResultRequiered(true);
      return false;
    }

    if (
      !isResponsesDisabled &&
      getGlobalConfig("additional_remarks_required", false) &&
      (comments === null || comments === "")
    ) {
      setObservationRequired(true);
      return false;
    }

    setPending(true);
    const payload = {
      call_comment: isOtherSubjectDisabled ? null : customSubject,
      call_response: callResult.id,
      comments,
    };

    if (!isResponsesDisabled) {
      payload.student_response = studentResponse.id;
    }

    if (derive && derive !== "") {
      payload.derived_action_plan = derive;
    }

    if (callResponseValue !== null && callResponseValueType !== null) {
      if (callResponseValueType === "date") {
        payload.call_comment = moment(callResponseValue).format("YYYY-MM-DD");
      } else if (callResponseValueType === "datetime") {
        payload.call_comment = moment(callResponseValue).format(
          "YYYY-MM-DD HH:mm"
        );
      } else {
        payload.call_comment = callResponseValue;
      }
    } else if (callResultValue !== null && callResultValueType !== null) {
      if (callResultValueType === "date") {
        payload.call_comment = moment(callResultValue).format("YYYY-MM-DD");
      }
      if (callResultValueType === "datetime") {
        payload.call_comment = moment(callResultValue).format(
          "YYYY-MM-DD HH:mm"
        );
      } else {
        payload.call_comment = callResultValue;
      }
    }

    const { status } = await updateTask(task.id, payload, task.external);

    dispatch(getTaskList());
    dispatch(cleanTaskGroup());
    setPending(false);
    setResponsesDisabled(true);
    if (status === 200) {
      toast.success(t("success_save_task"));
    } else {
      toast.error(t("error_save_task"));
    }
  }, [
    dispatch,
    callResult,
    comments,
    customSubject,
    isOtherSubjectDisabled,
    isResponsesDisabled,
    studentResponse,
    callResponseValue,
    task.id,
    callResponseValueType,
    callResultValue,
    callResultValueType,
    task.external,
    t,
    derive,
  ]);

  const handleResultChange = (result) => {
    setCallResult(result);
    setCallResponseValue(null);
    setCallResponseValueType(null);
    setCallResultValueType(result.input_type);
    setCallResultRequiered(false);

    if (!result.enable_reactions && resultCategory === "normal") {
      setStudentResponse("");
      setResponsesDisabled(true);
    } else {
      setResponsesDisabled(false);
    }
    // emit new change to the parent component
    onChange({ key: "call_response", value: result.id });
  };

  const handleResponseChange = (response) => {
    setStudentResponse(response);
    setCallResponseValue(null);
    setRequiredValue(false);
    setOtherSubjectDisabled(response.code.toLocaleLowerCase() !== "resp_other");
    setIsInvalidOther(false);
    setCallResponseValueType(response.input_type);
    setCallResultValueType(null);
    setCallResponseValue(null);

    setStudentResRequired(false);
    // emit new change to the parent component
    onChange({ key: "student_response", value: response.id });
  };

  const handleCommentChange = (event) => {
    const studentComments = event.target.value;
    const shrinkedComment = String(studentComments).substring(0, 1000);
    setComments(shrinkedComment);
    setObservationRequired(false);
    // emit new change to the parent component
    onChange({
      key: "comments",
      value: shrinkedComment,
    });
  };

  const handleSubjectChange = (event) => {
    setCustomSubject(event.target.value);
    setIsInvalidOther(false);
    // emit new change to the parent component
    onChange({ key: "call_comment", value: event.target.value });
  };

  const renderLastComments = useCallback(() => {
    const { last_contact: lastContact = null } = task;
    if (!lastContact) return null;
    return (
      <Row className="pt-1 px-4">
        <Col className={styles.commentsBlock}>
          <div className="d-flex">
            <div>
              <p style={{ fontSize: 14 }} className="font-weight-bold">
                <u>{t("last_contact")}:</u>
              </p>
            </div>
            <div className="ml-2">
              <div style={{ fontSize: 14 }}>
                {!lastContact.call_comment && !lastContact.comments ? (
                  <>
                    {lastContact.call_response && (
                      <div>{lastContact.call_response}</div>
                    )}
                    <div>{lastContact.contact_reason}</div>
                  </>
                ) : (
                  <>
                    {lastContact.call_response && (
                      <div className="d-flex">
                        <div style={{ fontWeight: "500" }}>
                          {_.upperFirst(t("response"))}:
                        </div>
                        <div className="ml-1">
                          {lastContact.call_response}
                          {lastContact.call_comment && (
                            <div>{lastContact.call_comment}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {lastContact.comments && (
                      <div>
                        <span style={{ fontWeight: "500" }}>
                          {_.upperFirst(t("observations"))}:
                        </span>{" "}
                        {lastContact.comments}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                <small className="secondary_color font-weight-bold">
                  <AnonymousContainer isAnonymous={user.anonymous}>
                    {lastContact.user_name}{" "}
                  </AnonymousContainer>
                  {lastContact.closed_at
                    ? `${moment(lastContact.closed_at).format(
                        "DD/MM/YYYY - HH:mm"
                      )}h`
                    : null}
                </small>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }, [task, user.anonymous, t]);

  const renderLastNote = useCallback(() => {
    const { recentNote: note = null } = student;
    if (!note) return null;

    const fullName = `${note.user.first_name ?? ""} ${
      note.user.last_name ?? ""
    }`;
    const createdAt = moment(note.created_at).format("DD-MM-YYYY HH:mm:SS");

    return (
      <Row className="pt-1 px-4">
        <Col className={styles.commentsBlock}>
          <div className="d-flex">
            <div>
              <p style={{ fontSize: 14 }} className="font-weight-bold">
                <u>Última observación:</u>
              </p>
            </div>
            <div className="ml-2">
              <p style={{ fontSize: 14 }}>{note.note}</p>
              <div>
                <small className="secondary_color font-weight-bold">
                  <AnonymousContainer isAnonymous={user.anonymous}>
                    {fullName ?? ""}{" "}
                  </AnonymousContainer>
                  {createdAt}
                </small>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }, [student, user.anonymous]);

  const onChangeValue = (value, type) => {
    setCallResponseValue(value);
    setRequiredValue(false);
  };

  const onChangeCallValue = (value, type) => {
    setCallResultValue(value);
    setRequiredValue(false);
    setCallResultRequiered(false);
  };

  return (
    <>
      <Card className={styles.callingActionBlock}>
        <Card.Body className="pt-0">
          <Row className={styles.callingActionHeader}>{children}</Row>

          {renderLastComments()}

          {renderLastNote()}

          <Row className={`pt-2 px-4 ${styles.content}`}>
            <Col>
              <p className={styles.callResultHeader}>
                {_.upperFirst(t("result"))}:
              </p>
              <CallResults
                rows={callResults}
                onChange={handleResultChange}
                category={resultCategory}
                selected={callResult}
                parentId={task.id}
                onChangeValue={onChangeCallValue}
                callResultValue={callResultValue}
              />
              {callResultRequiered && (
                <p style={{ color: "red" }}>Selecciona una opción!.</p>
              )}
            </Col>
            <Col>
              <p className={styles.callResultHeader}>
                {_.upperFirst(
                  getText(
                    "student_reaction",
                    "startCase",
                    t("student_reaction", {
                      student: translation("student", "", t("student")),
                    })
                  )
                )}
              </p>
              <CallResponses
                rows={callResponses}
                onChange={handleResponseChange}
                selected={studentResponse}
                parentId={task.id}
                hasDisabledOptions={isResponsesDisabled}
                callResponseValue={callResponseValue}
                onChangeValue={onChangeValue}
                requiredValue={requiredValue}
                task={task}
              />
              {studentResponseRequired && (
                <p style={{ color: "red" }}>Selecciona una opción!.</p>
              )}
              {callResponses &&
                callResponses
                  .map((item) => item.code)
                  .includes("resp_other") && (
                  <div>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        maxLength={60}
                        placeholder={t("other_reaction")}
                        value={customSubject}
                        onChange={handleSubjectChange}
                        disabled={isOtherSubjectDisabled}
                        isInvalid={isInvalidOther}
                      />
                      {isInvalidOther && (
                        <p style={{ color: "red" }}>{t("required_field")}</p>
                      )}
                    </Form.Group>
                  </div>
                )}
              {tab === "initiatives" &&
                moments &&
                activeInitiative &&
                activeInitiative.moments &&
                activeInitiative.moments.length > 0 &&
                activeInitiative.enter_action_plans &&
                !task.external && (
                  <div style={{ marginBottom: "1rem" }}>
                    <div style={{ marginBottom: "0.5rem", fontWeight: "500" }}>
                      Derivar a:{" "}
                    </div>
                    <Form.Control
                      as="select"
                      value={derive}
                      onChange={(event) => {
                        let value = event.target.value;
                        setDerive(value);
                      }}
                      disabled={isResponsesDisabled}
                    >
                      <option value="">---Seleccionar---</option>
                      {moments
                        .filter((item) =>
                          activeInitiative.moments.includes(item.id)
                        )
                        .map((item, key) => (
                          <option key={`moment-derive-${key}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Control>
                  </div>
                )}
              <div>
                <Form.Group controlId="CallingForm.ControlTextarea1">
                  <Form.Label>{_.upperFirst(t("comment"))}</Form.Label>
                  <NBTextArea
                    value={comments}
                    maxLength={1000}
                    onChange={handleCommentChange}
                    errorText={observationRequired ? t("required_field") : null}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {hideSaveButton ? null : (
        <NButton
          cssStyles={styles.callingSaveButton}
          onHandleClick={() => {
            if (
              !isOtherSubjectDisabled &&
              (customSubject === null || customSubject === "")
            ) {
              setIsInvalidOther(true);
            } else {
              if (
                studentResponse &&
                studentResponse.input_type !== null &&
                (callResponseValue === null || callResponseValue === "")
              ) {
                setRequiredValue(true);
              } else {
                saveTask();
              }
            }
          }}
          caption={
            <>
              {isPending && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              {_.upperFirst(t("save"))}
            </>
          }
          isDisabled={isPending}
        />
      )}
    </>
  );
};

NCallingInfo.propTypes = {
  task: PropTypes.shape({}).isRequired,
  resultCategory: PropTypes.oneOf(["normal", "exception"]).isRequired,
  onChange: PropTypes.func,
  hideSaveButton: PropTypes.bool,
};

NCallingInfo.defaultProps = {
  onChange: () => {},
  hideSaveButton: false,
};

export default React.memo(NCallingInfo);
