import {
  GET_MOMENT,
  SET_MOMENT,
  GET_STUDENTS_TRACKING,
  SET_STUDENTS_TRACKING,
  GET_ENTERABLE_LIST_ACTION_PLANS,
  SET_ENTERABLE_LIST_ACTION_PLANS,
} from "store/actions/moment.actions";
const initialState = {
  moment: null,
  list: [],
  enterable_list_action_plans: [],
  pending: {
    moment: false,
    list: false,
    enterable_list_action_plans: false,
  },
  paginationTracking: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
};

const momentReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOMENT: {
      return {
        ...state,
        moment: null,
        pending: { ...state.pending, moment: true },
      };
    }

    case SET_MOMENT: {
      const results = action.payload;
      return {
        ...state,
        moment: results,
        pending: { ...state.pending, moment: false },
      };
    }

    case GET_STUDENTS_TRACKING: {
      return {
        ...state,
        list: [],
        pending: { ...state.pending, list: true },
        paginationTracking: {
          ...state.paginationTracking,
          count: -1,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_STUDENTS_TRACKING: {
      const { count, next, previous, results } = action.payload;

      let auxPagination = { ...state.paginationTracking };
      auxPagination = { ...auxPagination, ...{ count, next, previous } };

      return {
        ...state,
        list: results,
        pending: { ...state.pending, list: false },
        paginationTracking: auxPagination,
      };
    }
    case GET_ENTERABLE_LIST_ACTION_PLANS: {
      return {
        ...state,
        enterable_list_action_plans: [],
        pending: { ...state.pending, enterable_list_action_plans: true },
      };
    }
    case SET_ENTERABLE_LIST_ACTION_PLANS: {
      const results = action.payload;
      return {
        ...state,
        enterable_list_action_plans: results,
        pending: { ...state.pending, enterable_list_action_plans: false },
      };
    }

    default:
      return { ...state };
  }
};

export default momentReducers;
