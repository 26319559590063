import { generateUrlWithParams } from "utils";
import { CancelToken } from "axios";
import api from "./api";
import logger from "./logService";

const tokens = {
  studentsInMoments: null,
  advancedSearch: null,
};

export const getStudents = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/person/", queryParams);
  return api.get(finalUrl);
};

export const searchStudentsInMoment = async (queryParams, payload) => {
  if (tokens.studentsInMoments)
    tokens.studentsInMoments.cancel("Operation canceled due to new request.");
  tokens.studentsInMoments = CancelToken.source();
  const finalUrl = generateUrlWithParams(
    "crm/task/students-by-moment/",
    queryParams
  );

  try {
    const response = await api.post(finalUrl, payload, {
      cancelToken: tokens.studentsInMoments.token,
    });
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const advancedSearch = async (queryParams) => {
  if (tokens.advancedSearch)
    tokens.advancedSearch.cancel("Operation canceled due to new request.");
  tokens.advancedSearch = CancelToken.source();
  const finalUrl = generateUrlWithParams(
    "crm/person/advanced-search/",
    queryParams
  );

  try {
    const response = await api.get(finalUrl, {
      cancelToken: tokens.advancedSearch.token,
    });
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

export const getStudentTags = () => {
  return api.get("crm/person/tags/");
};

export const getStudentsByTag = (tagId, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/tag/${tagId}/persons/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getStudentsInCourses = (listCourses, queryParams) => {
  const finalUrl = generateUrlWithParams("crm/person/in-courses/", queryParams);
  return api.post(finalUrl, { courses: listCourses });
};

export const getStudentNotes = (studentID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentID}/notes/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const createNote = async (payload) => {
  try {
    const response = await api.post(`crm/student-note/`, payload);
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export const fetchLastNote = async (studentID) => {
  try {
    const response = await getStudentNotes(studentID, { top: 1 });
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export const getInfoCustomPerson = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${id}/custom_info/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getStudentInsights = (studentID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentID}/student_insights/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getStudentRisk = (studentID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentID}/student_risk/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getStudentAcademicProgress = (studentID, queryParams) => {
  try {
    const finalUrl = generateUrlWithParams(
      `crm/student-tracking/${studentID}/students_progress/`,
      queryParams
    );
    return api.get(finalUrl);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};

export const getEnterableActionPlans = (studentID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentID}/enterable-action-plans/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const enterActionPlan = async (studentID, payload) => {
  try {
    const response = await api.post(
      `crm/person/${studentID}/enter-action-plan/`,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    return { ...Object.fromEntries(err), data: null };
  }
};
export const getChangeLogs = (studentID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/student-tracking/${studentID}/change_logs/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getGenerationEvents = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/generation-event/past-events/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getRiskHistory = (studentID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentID}/risk-history/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const getDownloadDocumentAdvice = (eventID, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/generation-event/${eventID}/download-excel/`,
    queryParams
  );
  return api.get(finalUrl, { responseType: "blob" });
};

export const updateStudentAttendanceFile = async (payload) => {
  try {
    const response = await api.post(
      `crm/generation-event/student-generation-events/`,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};
