import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Table, Col, Row, Collapse } from "react-bootstrap";

import { NActionBlock } from "components";
import { Datalist } from "components/Skeletons";
import ContainerEmpty from "../ContainerEmpty";
import { validateExcludeColumn } from "utils";
import EmptyListSVG from "assets/images/EmptyListSVG";

const NTable = ({
  tableName = "",
  onChange,
  columns,
  dataSource,
  check,
  upperCaseHeader,
  isPending,
  collapse,
  toggleTasks,
  paddingHeader,
  keyValue = "id",
  defaultValue,
  selectAll = false,
  textEmpty,
  permissions = [],
  hasSelect = false,
  widgetEmpty,
  widthSkeletons = 1500,
  heightSkeletons = 400,
  pagination,
  stylePagination = {},
  fixed = false,
  showEmptyWidget = true,
  widgetCollapse,
  positionOpenCollapse = [],
  styleCustomCollapse = {},
  customStyle = {},
  keyName = "",
  descriptionEmpty,
}) => {
  const [selected, setSelected] = useState(defaultValue || []);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (!selectAll && !hasSelect) {
      setSelected([]);
    }
  }, [selectAll, hasSelect]);

  useEffect(() => {
    if (!hasSelect) {
      setSelected([]);
    }
  }, [hasSelect]);

  useEffect(() => {
    if (onChange) {
      if (selected.length > 0 && selected[0] === 0) {
        onChange(selected, { selectAll: true });
      } else {
        onChange(selected, { selectAll: false });
      }
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAll = () => {
    setSelected([0]);
  };

  const cleanAll = () => {
    setSelected([]);
  };

  const getDataItem = (item, fields_string) => {
    let fields = fields_string.split("__");
    let count = 0;
    let data = {};
    for (let y = 0; y < fields.length; y++) {
      if (count === 0) {
        if (fields[y] in item) {
          data = item[fields[y]];
          if (!data) {
            return "---";
          }
          count++;
        } else {
          return "---";
        }
      } else {
        if (fields[y] in data) {
          data = data[fields[y]];
        } else {
          return "---";
        }
      }
    }
    if (fields_string === "action_plans") {
      return data && data.length > 0 ? data.join("\n") : "---";
    } else {
      return data;
    }
  };

  const getClassNameItem = (dataSource, keyRow, fixed, isDisabled) => {
    const styles = [];
    if (dataSource.length - 1 === keyRow) {
      styles.push("column-background-border-bottom");
    }
    if (fixed) {
      styles.push("sticky-column");
    }
    if (fixed && isDisabled) {
      styles.push("disabled-sticky");
    }
    return styles.join(" ");
  };

  if (isPending) {
    return (
      <Row key={"d-table-general"}>
        <Col className="d-flex justify-content-center ">
          <Datalist width={widthSkeletons} height={heightSkeletons} />
        </Col>
      </Row>
    );
  }
  if (!isPending && dataSource.length === 0) {
    if (!showEmptyWidget) return <></>;
    if (widgetEmpty) return widgetEmpty();
    return (
      <div>
        <ContainerEmpty
          version="2"
          text={textEmpty ? textEmpty : t("no_data_to_display")}
          image={<EmptyListSVG />}
          description={
            descriptionEmpty ? (
              descriptionEmpty
            ) : (
              <div>
                Aquí se mostrará toda la información <br /> cuando esté
                disponible
              </div>
            )
          }
        />
      </div>
    );
  }

  return (
    <Wrapper ref={wrapperRef}>
      {pagination && (
        <div style={{ fontSize: "var(--font-size-small)", ...stylePagination }}>
          <strong>{pagination.count}</strong> {t("teams_found")}
        </div>
      )}
      <TableS fixed={fixed ? 1 : 0} style={customStyle}>
        <thead>
          <tr>
            {check && (
              <TableItemHeader>
                <input
                  type="checkbox"
                  style={{ marginTop: "4px" }}
                  checked={
                    (selected.length > 0 && selected[0] === 0) || selectAll
                  }
                  onChange={(event) => {
                    if (event.target.checked) checkAll();
                    if (!event.target.checked) cleanAll();
                  }}
                />
              </TableItemHeader>
            )}
            {columns
              .filter((item) => {
                if (validateExcludeColumn(tableName, item.columnName)) {
                  return false;
                }
                return item.permission
                  ? permissions.includes(item.permission)
                  : true;
              })
              .map((item, key) => {
                return (
                  <TableItemHeader
                    key={`header-table-${key}`}
                    center={item.center ? 1 : 0}
                    padding={paddingHeader}
                    className={`
                    ${"column-background-border-top "}
                    ${item.fixed ? "sticky-column" : ""}
                    `}
                    style={item.style ? item.style : {}}
                  >
                    {upperCaseHeader && item.title
                      ? item.title.toUpperCase()
                      : item.title}
                  </TableItemHeader>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item, keyRow) => {
            return (
              <React.Fragment key={`tr-${keyRow}-fragment`}>
                <tr
                  key={`tr-${keyRow}`}
                  className={item.isDisabled ? "disabledDiv" : ""}
                >
                  {check && (
                    <td width="5%">
                      <input
                        type="checkbox"
                        style={{ marginTop: "4px" }}
                        checked={selected.includes(item[keyValue]) || selectAll}
                        onChange={(event) => {
                          if (event.target.checked) {
                            let newSelect = selected.filter(function (select) {
                              return select !== 0;
                            });
                            setSelected([...newSelect, item[keyValue]]);
                          }
                          if (!event.target.checked && !selectAll) {
                            let newSelect = selected.filter(function (select) {
                              return select !== item[keyValue];
                            });
                            setSelected(newSelect);
                          }
                          if (!event.target.checked && selectAll) {
                            cleanAll();
                            setSelected([item[keyValue]]);
                          }
                        }}
                      />
                    </td>
                  )}
                  {columns
                    .filter((item) => {
                      if (validateExcludeColumn(tableName, item.columnName)) {
                        return false;
                      }
                      return item.permission
                        ? permissions.includes(item.permission)
                        : true;
                    })
                    .map((column, keyColumn) => {
                      return (
                        <td
                          key={`td-${keyColumn}-${column.key}`}
                          width={
                            !fixed
                              ? column.width
                                ? column.width
                                : "auto"
                              : "auto"
                          }
                          className={getClassNameItem(
                            dataSource,
                            keyRow,
                            column.fixed,
                            item.isDisabled
                          )}
                          style={column.style ? column.style : {}}
                        >
                          <ContainerItem
                            center={column.contentCenter ? 1 : 0}
                            style={
                              fixed
                                ? column.width
                                  ? { minWidth: `${column.width}px` }
                                  : {}
                                : {}
                            }
                          >
                            {column.dataIndex
                              ? column.render
                                ? column.render(
                                    item[column.dataIndex],
                                    item,
                                    keyRow,
                                    getDataItem,
                                    keyName
                                  )
                                : item[column.dataIndex]
                              : column.render(
                                  item,
                                  getDataItem,
                                  keyRow,
                                  keyName
                                )}
                          </ContainerItem>
                        </td>
                      );
                    })}
                </tr>
                {collapse &&
                  positionOpenCollapse.includes(keyRow) &&
                  (widgetCollapse ? (
                    <tr
                      key={`tr-${keyRow}-collapse`}
                      className="w-100"
                      style={{ border: "0px" }}
                    >
                      <td
                        colSpan={columns.length}
                        style={{
                          padding: "0xp",
                          left: "0px",
                          ...styleCustomCollapse,
                        }}
                        className={"sticky-column "}
                      >
                        {widgetCollapse(item, keyRow)}
                      </td>
                    </tr>
                  ) : null)}
                {collapse && item.isOpened && (
                  <tr
                    key={`tr-${keyRow}-collapse`}
                    className="w-100"
                    style={{ border: "0px" }}
                  >
                    <td
                      colSpan={columns.length}
                      style={{ padding: "0xp", left: "0px" }}
                      className={"sticky-column"}
                    >
                      <Row>
                        <Col>
                          <Collapse in={item.isOpened}>
                            <div id="collapse-content">
                              <div
                                style={{
                                  maxWidth:
                                    columns.length === 9 &&
                                    permissions.length === 0
                                      ? "100%"
                                      : wrapperRef.current &&
                                        wrapperRef.current?.offsetWidth > 793
                                      ? `${
                                          wrapperRef.current?.offsetWidth - 10
                                        }px`
                                      : "1070px",
                                  position: "sticky",
                                  left: "0px",
                                }}
                              >
                                <NActionBlock
                                  task={item}
                                  channel={item.channel}
                                />
                              </div>
                            </div>
                          </Collapse>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </TableS>
    </Wrapper>
  );
};

export default NTable;

NTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  upperCaseHeader: PropTypes.bool,
  check: PropTypes.bool,
  onChange: PropTypes.func,
};

NTable.defaultProps = {
  upperCaseHeader: false,
};

const TableS = styled(Table)`
  width: 100%;
  // ${(props) => (props.fixed === 1 ? "table-layout: fixed" : "")};
  th {
    border-bottom: none !important;
    border-top: none !important;
    padding-left: 12px;
    padding-right: 12px;
  }
  td {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: inherit;
    border-top: 1px solid #dde3ec !important;
  }
  margin-bottom: 0px !important;

  .sticky-column {
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
  }

  .disabled-sticky {
    background-color: var(--color-dark-aqua) !important;
  }

  .column-background-border-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .column-background-border-bottom {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TableItemHeader = styled.th`
  font-size: var(--font-size-small);
  color: #7c7f86;
  ${(props) =>
    props.padding
      ? "padding: " + props.padding + ";"
      : ` padding-top: 16px !important;
  padding-bottom: 16px !important;`}

  ${(props) => (props.center ? "text-align:center;" : "")}
`;

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  // white-space: nowrap;
`;

const ContainerItem = styled.div`
  ${(props) => (props.center ? "text-align: center;" : "")}
`;
