/* eslint-disable no-param-reassign */
import {
  GET_DASH_LIST,
  SET_DASH_LIST,
  REGISTER_ERROR,
  TOGGLE_PENDING,
  SET_STATS_DATA,
  TOGGLE_SELECTED,
  TOGGLE_COLLAPSE_TASK,
  UPDATE_DASH_STUDENT_INFO,
  GET_STATS_DATA,
  SET_INSIGHTS_TEAM,
  SET_INSIGHTS_OWN,
  SET_INSIGHTS_RISKS,
  SET_INSIGHTS_PROGRESS,
  SET_TOP_CALLERS,
  SET_INSIGHTS_TRACKING,
  SET_INSIGHTS_PROGRESS_WEEK,
  SET_INSIGHTS_PROGRESS_DAILY,
} from "store/actions/dashboard.actions";

const initialState = {
  tasks: [],
  pending: {
    list: false,
    stats: false,
    insightsTeam: false,
    insightsTracking: false,
    insightsOwn: false,
    insightsRisks: false,
    insightsProgress: false,
    insightsProgressWeek: false,
    insightsProgressDaily: false,
    topCallers: false,
  },
  pagination: {
    count: 0,
    next: null,
    previous: null,
    page: 1,
    pageSize: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  },
  stats: {
    last_sync: null,
    days_interval: null,
    period: null,
    metrics: [],
    initiatives: [],
    moments: [],
    count_initiatives: 0,
    count_moments: 0,
  },
  insightsTeam: null,
  insightsTracking: null,
  insightsOwn: null,
  insightsRisks: null,
  insightsProgress: null,
  insightsProgressWeek: null,
  insightsProgressDaily: null,
  isActive: true, // used for navbar menu only...
  collapseTaskID: null,
  topCallers: null,
};

const dashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASH_LIST: {
      return {
        ...state,
        pending: { ...state.pending, list: true },
        pagination: {
          ...state.pagination,
          page: action.payload.page,
          pageSize: action.payload.page_size,
        },
      };
    }

    case SET_DASH_LIST: {
      const { count, next, previous, results } = action.payload;
      let auxPagination = { ...state.pagination };
      auxPagination = { ...auxPagination, count, next, previous };

      results.map((task) => {
        task.isOpened = false;
        task.isDisabled = false;
        task.isChecked = false;
        return null;
      });

      return {
        ...state,
        tasks: results,
        pending: { ...state.pending, list: false },
        pagination: auxPagination,
      };
    }

    case TOGGLE_PENDING: {
      return {
        ...state,
        pending: { ...state.pending, [action.payload.attr]: action.payload.sw },
      };
    }

    case TOGGLE_SELECTED: {
      // dashboard menu
      return { ...state, isActive: action.payload };
    }

    case GET_STATS_DATA: {
      return {
        ...state,
        pending: { ...state.pending, stats: true },
      };
    }

    case SET_STATS_DATA: {
      return {
        ...state,
        stats: action.payload,
        pending: { ...state.pending, stats: false },
      };
    }

    case TOGGLE_COLLAPSE_TASK: {
      const { taskId, sw } = action.payload;
      const auxTasks = [...state.tasks];
      auxTasks.map((task) => {
        task.isOpened = task.id === taskId && sw;
        task.isDisabled = !(task.id === taskId) && sw;
        return null;
      });

      return { ...state, collapseTaskID: false };
    }

    case UPDATE_DASH_STUDENT_INFO: {
      const studenId = action.payload.id;
      const fullName = `${action.payload.first_name} ${action.payload.last_name}`;
      const auxTasks = [...state.tasks];
      const studentTasks = auxTasks.filter(
        (task) => task.student_tracking.id === studenId
      );
      studentTasks.map((task) => {
        task.student_tracking.full_name = fullName;
        return null;
      });

      return { ...state, tasks: auxTasks };
    }

    case REGISTER_ERROR: {
      const auxPending = { ...state.pending };
      if (action.payload.key) auxPending[action.payload.key] = false;

      return { ...state, pending: auxPending };
    }

    case SET_INSIGHTS_TEAM: {
      const results = action.payload;
      return {
        ...state,
        insightsTeam: results,
        pending: { ...state.pending, insightsTeam: false },
      };
    }

    case SET_INSIGHTS_TRACKING: {
      const results = action.payload;
      return {
        ...state,
        insightsTracking: results,
        pending: { ...state.pending, insightsTracking: false },
      };
    }

    case SET_INSIGHTS_OWN: {
      const results = action.payload;
      return {
        ...state,
        insightsOwn: results,
        pending: { ...state.pending, insightsOwn: false },
      };
    }

    case SET_INSIGHTS_RISKS: {
      const results = action.payload;
      return {
        ...state,
        insightsRisks: results,
        pending: { ...state.pending, insightsRisks: false },
      };
    }

    case SET_INSIGHTS_PROGRESS: {
      const results = action.payload;
      return {
        ...state,
        insightsProgress: results,
        pending: { ...state.pending, insightsProgress: false },
      };
    }
    case SET_INSIGHTS_PROGRESS_WEEK: {
      const results = action.payload;
      return {
        ...state,
        insightsProgressWeek: results,
        pending: { ...state.pending, insightsProgressWeek: false },
      };
    }
    case SET_INSIGHTS_PROGRESS_DAILY: {
      const results = action.payload;
      return {
        ...state,
        insightsProgressDaily: results,
        pending: { ...state.pending, insightsProgressDaily: false },
      };
    }

    case SET_TOP_CALLERS: {
      const { data: results, attr } = action.payload;
      return {
        ...state,
        topCallers: results,
        pending: { ...state.pending, [attr]: false },
      };
    }

    default:
      return { ...state };
  }
};

export default dashboardReducers;
