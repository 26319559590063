import { generateUrlWithParams } from "utils";
import api from "./api";
import logger from "./logService";

const getTasks = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/task/list/", queryParams);
  return api.get(finalUrl);
};

const getStudentResponses = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/student-response/", queryParams);
  return api.get(finalUrl);
};

const getCallResults = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/call-result/", queryParams);
  return api.get(finalUrl);
};

const getGroupedTasks = (studentId, moment) => {
  return api.get(
    `crm/task/list/?student_tracking_id=${studentId}&&moment=${moment}&&list_group=1`
  );
};

const getPersonsProfile = (studentId, queryParams = {}) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentId}/`,
    queryParams
  );
  return api.get(finalUrl);
};

const getStudentTrackingsPerson = (studentId, queryParams = {}) => {
  const finalUrl = generateUrlWithParams(
    `crm/person/${studentId}/student_tracking_list/`,
    queryParams
  );
  return api.get(finalUrl);
};

const getPersonTasksHistory = (personId) => {
  return api.get(`crm/person/${personId}/task_history/`);
};

const getPersonHistory = (personId, filters) => {
  const params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    params.append(key, filters[key]);
  });
  const path = `crm/person/${personId}/history/`;
  return api.get(path, { params });
};

const getMoments = () => {
  return api.get("crm/moment/");
};

const getUsedTags = () => {
  return api.get("crm/tag/used/");
};

const getActionTemplates = (channel) => {
  return api.post("crm/action-template/?page_size=100/", { channel });
};

const templateDispatcher = (payload) => {
  return api.post("crm/template-dispatcher/", payload);
};

const updateTask = async (taskId, payload, external) => {
  try {
    let url = `crm/task/${taskId}/`;
    if (external) {
      url = `crm/task/${taskId}/?external=true`;
    }
    const response = await api.put(url, payload);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

const editPersonProfile = async ({ studentId, payload }) => {
  try {
    const response = await api.patch(`crm/person/${studentId}/`, payload);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

const dispatchTaskEvent = async (taskId, payload, row) => {
  try {
    if (row.external) {
      const response = await api.post(
        `initiatives/simple-task/${taskId}/dispatch-event/`,
        payload
      );
      return Promise.resolve(response);
    } else {
      const response = await api.post(
        `crm/task/${taskId}/dispatch-event/`,
        payload
      );
      return Promise.resolve(response);
    }
  } catch (err) {
    logger.log(err);
    return err;
  }
};

const getCourses = () => {
  return api.get("crm/course/");
};

const getAllCampus = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/course/campus/", queryParams);
  return api.get(finalUrl);
};
const getListCampus = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/campus/", queryParams);
  return api.get(finalUrl);
};

const getCoursesFromCampus = async ({ queryParams, payload }) => {
  const finalUrl = generateUrlWithParams("crm/course/from-campus/", {
    page: queryParams.page,
    page_size: queryParams.page_size,
  });

  try {
    const response = await api.post(finalUrl, payload);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

const searchCourses = async ({ queryParams }) => {
  const finalUrl = generateUrlWithParams("crm/course/", queryParams);

  try {
    const response = await api.get(finalUrl);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

const sendReport = async (reportBody) => {
  try {
    const headers = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const response = await api.post(`crm/report/`, reportBody, headers);
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return err;
  }
};

const getStudentCourses = (studentId) => {
  return api.get(`crm/person/${studentId}/courses/`);
};

const getStudentMoments = (studentId) => {
  return api.get(`crm/person/${studentId}/moments/`);
};

const getPeriods = (queryParams) => {
  const finalUrl = generateUrlWithParams("crm/period/", queryParams);
  return api.get(finalUrl);
};

export {
  getTasks,
  getStudentResponses,
  updateTask,
  getGroupedTasks,
  getMoments,
  getUsedTags,
  getCallResults,
  getPersonsProfile,
  getStudentTrackingsPerson,
  getPersonTasksHistory,
  editPersonProfile,
  dispatchTaskEvent,
  getCourses,
  searchCourses,
  sendReport,
  getActionTemplates,
  templateDispatcher,
  getAllCampus,
  getCoursesFromCampus,
  getPersonHistory,
  getListCampus,
  getStudentCourses,
  getStudentMoments,
  getPeriods,
};
