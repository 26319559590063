import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import _ from "lodash";
import { Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { NButton, TextError } from "components/Nimbiv2";
import { RowItem } from "components/Nimbiv2/styled";
import { useSelector } from "react-redux";
import { getDownloadDocumentAdvice } from "services/student.service";
import { Typeahead } from "react-bootstrap-typeahead";

const DownloadAdvice = ({ show, onHide }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectGeneration, setSelectGeneration] = useState(null);
  const { generationEvents } = useSelector((state) => state.student);

  const downloadAdvice = async (values) => {
    setLoading(true);
    const { generation_event } = values;
    await getDownloadDocumentAdvice(generation_event).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Formato de asistencias a asesorias grupales.xlsx"
      );
      document.body.appendChild(link);
      link.click();
    });
    setLoading(false);
  };
  const validate = (values) => {
    let errors = {};
    if (!values.generation_list) {
      errors.generation_list = "Campo requerido";
      return errors;
    }
    if (!values.generation_event) {
      errors.generation_event = "Campo requerido";
      return errors;
    }
    return errors;
  };
  const onFormSubmit = (values) => {
    downloadAdvice(values);
    return true;
  };
  const listGeneration = useMemo(() => {
    if (generationEvents) {
      return generationEvents
        .filter((item) => item?.confirmed_count > 0)
        .map((item) => {
          return item.generation;
        });
    }
    return [];
  }, [generationEvents]);

  const listEventGeneration = useMemo(() => {
    if (selectGeneration) {
      return generationEvents.filter(
        (item) =>
          selectGeneration.length > 0 &&
          item.generation === selectGeneration[0] &&
          item?.confirmed_count > 0
      );
    }
    return [];
  }, [selectGeneration, generationEvents]);

  return (
    <ModalDesign
      show={show}
      onHide={() => {
        setSelectGeneration(null);
        onHide();
      }}
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "0px" }}>
        <Modal.Title className="h4">{t("download_consultancies")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginBottom: "40px" }}>
          {t("select_for_which_consultancy")}
        </div>
        <Formik initialValues={{}} validate={validate} onSubmit={onFormSubmit}>
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <div>
              <div>
                <Typeahead
                  id="generation_list"
                  //labelKey="name"
                  name="generation_list"
                  multiple={false}
                  onChange={(value) => {
                    setSelectGeneration(value);
                    setFieldValue("generation_event", null);
                    setFieldValue(
                      "generation_list",
                      value.length > 0 ? value[0] : null
                    );
                  }}
                  options={listGeneration}
                  placeholder="Seleccionar generación"
                  selected={selectGeneration}
                  clearButton
                />
                <TextError
                  text={errors["generation_list"]}
                  marginTop="5px"
                  icon={faExclamationCircle}
                />
              </div>

              {listEventGeneration && listEventGeneration?.length > 0 && (
                <div style={{ marginTop: "10px" }}>
                  <Typeahead
                    id="generation_event"
                    labelKey="event_name"
                    onChange={(value) => {
                      setFieldValue(
                        "generation_event",
                        value.length > 0 ? value[0]?.id : null
                      );
                    }}
                    options={listEventGeneration}
                    placeholder="Seleccionar la asesoría"
                    clearButton
                  />

                  <TextError
                    text={errors["generation_event"]}
                    marginTop="5px"
                    icon={faExclamationCircle}
                  />
                </div>
              )}

              <RowItem style={{ marginTop: "56px" }}>
                <Col xs="6">
                  <NButton
                    style={{
                      marginTop: "10px",
                      float: "right",
                      width: "100%",
                    }}
                    outlined
                    onClick={() => {
                      setFieldValue("generation_event", null);
                      setSelectGeneration(null);
                      onHide();
                    }}
                    disabled={loading}
                  >
                    {_.upperFirst(t("cancel"))}
                  </NButton>
                </Col>
                <Col xs="6">
                  <NButton
                    style={{
                      marginTop: "10px",
                      float: "right",
                      width: "100%",
                    }}
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                  >
                    {_.upperFirst(t("download"))}
                  </NButton>
                </Col>
              </RowItem>
            </div>
          )}
        </Formik>
      </Modal.Body>
    </ModalDesign>
  );
};
export default DownloadAdvice;

const ModalDesign = styled(Modal)`
  .modal-header {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
  }
  .modal-body {
    padding-top: 8px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal-content {
    border-radius: 8px;
  }
`;
