import React from "react";
import { uuidv4 } from "utils";
import { useSelector, useDispatch } from "react-redux";
import TaskHistory from "containers/Task/v2/TaskHistory";
import { CardNimbi } from "components/Nimbiv2/styled";
import { Form, Row, Col } from "react-bootstrap";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";
import styles from "containers/Student/Student.module.css";
import { NSpinnerPoints } from "components/Nimbiv2";

const HistoryTask = ({ isPending }) => {
  const {
    personHistory,
    personCourses: studentCourses,
    personMoments: studentMoments,
    personsProfile: person,
    isPending: { personhistory: isPendingHistory },
  } = useSelector((state) => state.crm);

  const dispatch = useDispatch();

  const courseSelector = React.createRef();
  const momentSelector = React.createRef();

  const fetchTasks = async (filters) => {
    const queryFilters = {};
    Object.keys(filters)
      .filter((el) => filters[el] !== "0")
      .forEach((el) => {
        queryFilters[el] = filters[el];
      });
    return dispatch(fetchPersonHistory(queryFilters));
  };

  return (
    <CardNimbi>
      {!isPending && person && !isPendingHistory && (
        <Row>
          <Col xs={6}>
            {studentCourses && studentCourses.length > 2 && (
              <div className={styles.dropDown}>
                <Form.Control
                  as="select"
                  style={{ width: "100%" }}
                  ref={courseSelector}
                  onChange={(event) => {
                    if (momentSelector && momentSelector.current) {
                      momentSelector.current.value = "0";
                    }
                    fetchTasks({
                      course_id: event.target.value,
                      include_notes: (event.target.value === "0") | 0,
                    });
                  }}
                >
                  {studentCourses.map((course) => {
                    return (
                      <option
                        key={`option-course-${course.id}`}
                        value={course.id}
                      >
                        {course.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            )}
          </Col>
          <Col xs={6}>
            {studentMoments && studentMoments.length > 2 && (
              <div className={styles.dropDown}>
                <Form.Control
                  as="select"
                  style={{ width: "100%" }}
                  ref={momentSelector}
                  onChange={(event) => {
                    if (courseSelector && courseSelector.current) {
                      courseSelector.current.value = "0";
                    }
                    fetchTasks({
                      moment_id: event.target.id,
                      include_externals: (event.target.value === "0") | 0,
                    });
                  }}
                >
                  {studentMoments.map((moment) => {
                    return (
                      <option
                        key={`option-course-${moment.id}`}
                        value={moment.id}
                      >
                        {moment.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            )}
          </Col>
          <Col xs="12">
            <div className="overflow-auto">
              {personHistory &&
                personHistory.map((m) => (
                  <TaskHistory
                    key={uuidv4(8)}
                    student={{
                      full_name: `${person.first_name} ${person.last_name}`,
                    }}
                    task={m}
                  />
                ))}
            </div>
          </Col>
        </Row>
      )}
      {!(!isPending && person && !isPendingHistory) && (
        <div>
          <NSpinnerPoints />
        </div>
      )}
    </CardNimbi>
  );
};
export default HistoryTask;
