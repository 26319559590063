import { generateUrlWithParams } from "utils";
import api from "services/api";
import logger from "./logService";

export const getMomentDetail = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(`crm/moment/${id}/`, queryParams);
  return api.get(finalUrl);
};
export const getMomentStudentsTracking = (id, queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/moment/${id}/student-trackings/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const enterMassiveStudent = async (id, payload) => {
  try {
    const response = await api.post(
      `crm/moment/${id}/student-enter-massive/`,
      payload
    );
    return Promise.resolve(response);
  } catch (err) {
    logger.log(err);
    return Object.fromEntries(err);
  }
};

export const getEnterableListActionPlans = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/moment/enterable-list-action-plans/`,
    queryParams
  );
  return api.get(finalUrl);
};

export const fetchListMomentReason = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    `crm/moment-enter-reason/`,
    queryParams
  );
  return api.get(finalUrl);
};
