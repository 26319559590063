import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

const NCalendarInput = ({ style, dataset, onChange }) => {
  const [startDate, setStartDate] = useState(null);

  const onChangeDate = (date) => {
    const dateformat = moment(date).format("YYYY-MM-DD");
    onChange("", "", dataset, dateformat);
    setStartDate(date);
  };

  return (
    <div className="mt-2" style={style}>
      <div>{dataset.text}</div>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        className="form-control"
        selected={startDate}
        onChange={(date) => onChangeDate(date)}
        isClearable
        disabledKeyboardNavigation
      />
    </div>
  );
};
export default NCalendarInput;
