/* eslint-disable react/require-default-props */
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faMinusCircle,
  faInfoCircle,
  faEnvelope,
  faPhoneAlt,
  faUserAlt,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { Collapse, Spinner, OverlayTrigger, Popover } from "react-bootstrap";

import { Row, Col, Form, Image, NBadge, NActionBlock } from "components";
import { PusherContext } from "store/pusher";
import { EClassID, typesTaskEvent } from "utils";
import { toast } from "react-toastify";
import { fetchGroupedTasks } from "store/actions/async/crm-async.action";
import { setRowInfo, toggleActionRow } from "store/actions/crm.actions";
import { dispatchTaskEvent } from "services/crmService";
import { fetchLastNote } from "services/student.service";
import ClientFactory from "models/ClientFactory";
import moment from "moment";
import "moment/locale/es";
import styles from "./Task.module.css";
import { useTranslation } from "react-i18next";

const ResultItem = ({ row, swModal, swProfileModal, hideCounter = false }) => {
  const Institution = new ClientFactory().client();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { lastNotification } = useContext(PusherContext);
  const rowStyles = `align-items-center ${row.isDisabled ? styles.locked : ""}
   ${styles.customBox} mb-2 no-gutters`;

  const [userPhoto, setUserPhoto] = useState(null);
  const [opened, setOpened] = useState(false);
  const [closed, setClosed] = useState(false);
  const [isGettingTaskStatus, setGettingTaskStatus] = useState(false);

  useEffect(() => {
    if (row) {
      setUserPhoto(row.last_contact?.user_photo ?? null);
    }
  }, [row]);

  const getScoreIcon = (score) => {
    if (score >= 50)
      return (
        <FontAwesomeIcon
          className="text-primary mr-2"
          size="2x"
          icon={faArrowAltCircleUp}
        />
      );
    if (score >= 30)
      return (
        <FontAwesomeIcon
          className="text-warning mr-2"
          size="2x"
          icon={faMinusCircle}
        />
      );

    if (score)
      return (
        <FontAwesomeIcon
          className="text-success mr-2"
          size="2x"
          icon={faArrowAltCircleDown}
        />
      );
  };

  const popover = () => (
    <Popover className={styles.contactPopover} id="contact-popover">
      <Popover.Content>
        <div className={styles.title}>{row.last_contact.contact_reason}</div>
        {row.last_contact.completed_at ? (
          <div className={styles.dateInfo}>
            {moment(row.last_contact.completed_at).format("DD/MM/YYYY - HH:mm")}
            h
          </div>
        ) : null}
        {row.last_contact.user_name ? (
          <div className={styles.contactUser}>
            <FontAwesomeIcon className="mr-3" icon={faUserAlt} size="1x" />
            {row.last_contact.user_name}
          </div>
        ) : null}
        <div style={{ display: "none" }} className={styles.historyLink}>
          Ver historial completo
        </div>
      </Popover.Content>
    </Popover>
  );

  const groupTasks = (task) => {
    dispatch(setRowInfo(task));
    dispatch(fetchGroupedTasks(task.student_tracking.id));
  };

  const processTaskRequest = async (action) => {
    const payload = {
      action,
      channel: process.env.REACT_APP_PUSHER_CHANNEL,
      event: process.env.REACT_APP_PUSHER_EVENT,
    };

    try {
      const {
        data: { result: response },
      } = await dispatchTaskEvent(row.id, payload, row);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.resolve(false);
    }
  };

  const toggleOpen = async () => {
    if (!opened && !row.isOpened) {
      setGettingTaskStatus(true);

      await Promise.resolve(
        setTimeout(async () => {
          const response = await processTaskRequest(typesTaskEvent.TAKEN);

          if (response) {
            const { student_tracking: student = null } = row;
            if (student && student.id) {
              const { data, status } = await fetchLastNote(student.student_id, {
                top: 1,
              });
              if (status === 200)
                // eslint-disable-next-line no-param-reassign
                row.student_tracking.recentNote =
                  data.results.length > 0 ? data.results[0] : null;
            }

            setOpened(true);
          } else {
            toast.error("La tarea 🎯 no está disponible!");
          }
          setGettingTaskStatus(false);
        }, 500) // wait for 500ms to show spinner loader
      );
    } else if (!opened && row.isOpened) {
      setOpened(true);
      setClosed(true);
    }
  };

  const printUserIcon = () => {
    return userPhoto ? (
      <Image
        className={`${styles.contactThumb} mb-2`}
        src={row.last_contact.user_photo}
        alt="photo"
        onClick={() => swModal(row)}
        aria-hidden="true"
        roundedCircle
      />
    ) : (
      <FontAwesomeIcon className="icons_color mb-2" icon={faUser} size="2x" />
    );
  };

  const displayLastContact = () => {
    if (row.last_contact) {
      const contactType = row.last_contact.channel;

      return (
        <div className="d-flex align-items-end">
          <div className="d-flex flex-column align-items-center">
            {contactType === "Call" ? (
              printUserIcon()
            ) : (
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                className="icons_color mb-2"
                size="2x"
                icon={faEnvelope}
                onClick={() => swModal(row)}
                aria-hidden="true"
              />
            )}

            <span
              style={{ cursor: "pointer" }}
              onClick={() => swModal(row)}
              aria-hidden="true"
            >
              <u>{moment(row.last_contact.closed_at).format("DD/MM/YYYY")}</u>
            </span>
          </div>

          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="right"
            overlay={popover()}
          >
            <FontAwesomeIcon className="text-muted ml-2" icon={faInfoCircle} />
          </OverlayTrigger>
        </div>
      );
    }

    return <span className={styles.notAssigned}>SIN GESTIONES PREVIAS</span>;
  };

  const displayExpirationDate = () => {
    if (row.expiration_date) {
      const today = moment();
      const expiration = moment(row.expiration_date);
      const diff = expiration.diff(today, "day");
      if (moment(row.expiration_date).isSame(today, "day")) {
        return <span className={styles.expirationMessage}>¡Hoy!</span>;
      }
      if (diff === 0) {
        return <span> &lt; 1 día</span>;
      }
      return <span>{diff} días</span>;
    }
    return <span className={styles.notAssigned}>{t("no_information")}</span>;
  };

  const getActionIcon = () => {
    let actionIcon = (
      <FontAwesomeIcon className="text-light" size="lg" icon={faPhoneAlt} />
    );

    if (row.channel === "Email")
      actionIcon = (
        <FontAwesomeIcon className="text-light" size="lg" icon={faEnvelope} />
      );

    return actionIcon;
  };

  const toggleTasks = async (action) => {
    if (action === typesTaskEvent.RELEASED) {
      await processTaskRequest(typesTaskEvent.RELEASED);
    }

    // lock/unlock others tasks
    dispatch(
      toggleActionRow({ taskId: row.id, lastTaskEvent: lastNotification })
    );
  };

  return (
    <Row className={`${styles.rowItem} ${rowStyles}`}>
      <Col xs={2}>
        <div
          className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
        >
          <Form.Check
            className="d-none font-weight-bold"
            type="checkbox"
            id="check1"
            custom
          />
          <div className="d-flex flex-column justify-content-left align-items-cente">
            <div className={`mr-1 ${styles.headerTitle}`}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => swProfileModal(row)}
                aria-hidden="true"
              >
                {row.student_tracking.full_name}&nbsp;&nbsp;
              </span>
              {!hideCounter && row.counter > 1 ? (
                <NBadge
                  onClick={() => groupTasks(row)}
                  text={`+${row.counter - 1}`}
                  kind="custom"
                  customStyle={{ fontSize: 13, cursor: "pointer" }}
                  isBold
                />
              ) : null}
            </div>
            <div className={`mr-1 mt-2`}>
              <NBadge
                text={`${row.student_tracking.document_type} ${row.student_tracking.document_number}`}
                title={EClassID.STUDENT}
              />
            </div>
            <div className={`${styles.headerTitle} ${styles.callOverdueBlock}`}>
              <NBadge
                text={`${row.student_tracking.external_id}`}
                title={EClassID.STUDENT}
              />
              &nbsp;&nbsp;
              {/* {row.student_tracking.tags.map((element) => (
                <NBadge key={uuidv4(4)} text={element.tag} kind="gray" />
              ))} */}
            </div>
            {row.priority ? (
              <div className={styles.callOverdueBlock}>
                <span className="validation_color">{row.priority}</span>&nbsp;
                <FontAwesomeIcon className="icons_color mr-2" icon={faFlag} />
              </div>
            ) : null}
          </div>
        </div>
      </Col>
      <Col xs={3}>
        <Institution.CourseColumnInfo
          enrollment={row.student_tracking}
          hasExtension={row.has_extension}
        />
      </Col>
      <Col xs={2} style={{ padding: "14px 8px" }}>
        <Institution.CampusColumnInfo enrollment={row.student_tracking} />
      </Col>
      <Col className="d-flex align-items-center justify-content-between" xs={2}>
        <div
          className={`d-flex justify-content-left align-items-center ${styles.firstColumn}`}
        >
          <div className="d-flex flex-column justify-content-left align-items-cente">
            <div className={`mr-1 ${styles.rowText}`}>
              {displayLastContact()}
            </div>
          </div>
        </div>
      </Col>
      <Col className="d-flex " xs={1} style={{ padding: "14px 8px" }}>
        <span className={`d-flex align-items-center mr-1 ${styles.rowText}`}>
          {getScoreIcon(row.priority_score)}
          {row.priority_score}
        </span>
      </Col>
      <Col xs={1} style={{ padding: "14px 8px" }}>
        <div className="d-flex flex-column align-items-center">
          {displayExpirationDate()}
        </div>
      </Col>
      <Col className={styles.actionCol} xs={1}>
        <div
          className={styles.actionColContent}
          onClick={() => toggleOpen()}
          aria-hidden="true"
        >
          <span className={styles.circleActionButton}>
            {isGettingTaskStatus ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              getActionIcon()
            )}
          </span>
        </div>
      </Col>
      <Row style={{ margin: 0 }} className="w-100">
        <Col>
          <Collapse
            in={(opened || row.isOpened) && !closed}
            onEntered={() => toggleTasks(typesTaskEvent.TAKEN)}
            onExited={() => toggleTasks(typesTaskEvent.RELEASED)}
          >
            <div id="collapse-content">
              <NActionBlock task={row} channel={row.channel} />
            </div>
          </Collapse>
        </Col>
      </Row>
    </Row>
  );
};

ResultItem.propTypes = {
  row: PropTypes.shape({}).isRequired,
  swModal: PropTypes.func.isRequired,
  hideCounter: PropTypes.bool,
};

export default ResultItem;
